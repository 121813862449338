import "../styles/index.scss";

const DraftSetup = () => {
  return (
    <div >
        DRAFT SETUP
    </div>
  );
};

export default DraftSetup;