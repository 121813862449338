import "../styles/index.scss";
// import PlayerFilter from "../components/player-filter";
// import DraftPlyrList from "../components/draft-list";
// import DraftInfo from "../components/draft-info";
// import DraftNews from "../components/draft-news";

const Draftboard = () => {
  return (
    <div
      style={{
        padding: "5px 0px 0px 155px", color: "black",
      }}
    >
      Fantasy Teams
    </div>
  );
};

export default Draftboard;
